import React, { useState } from "react";
import PropTypes from "prop-types";
import immutable from "object-path-immutable";
import { upperFirstChar } from "common/helpers/helpers";
import BusySpinner from "common/components/BusySpinner";

import CollapseChevron from "common/components/Collapsible/CollapseChevron";
import styles from "./AssignmentChangeList.module.scss";
import CsvFileDownload from "../CsvFileDownload";

const CUTOFF = 10;

const assignmentsKeys = ["siteName", "siteCity", "siteState", "siteZipCode", "siteCountry", "siteId", "userName", "userEmail", "isThirdPartyUser", "userRole", "siteReport", "snowtifyReport"];
const assignmentsHeaders = ["Name", "City", "State", "Zip Code", "Country", "Site ID", "User", "User Email", "Third Party User", "Role", "Site Report (PDF)", "Snowtify Report"];

const assignmentUpdatesKeys = ["siteName", "siteCity", "siteState", "siteZipCode", "siteCountry", "siteId", "userName", "userEmail", "isThirdPartyUser", "userRole", "oldSiteReport", "newSiteReport", "oldSnowtifyReport", "newSnowtifyReport"];
const assignmentUpdatesHeaders = ["Name", "City", "State", "Zip Code", "Country", "Site ID", "User", "User Email", "Third Party User", "Role", "Old Site Report (PDF)", "New Site Report (PDF)", "Old Snowtify Report", "New Snowtify Report"];

const assignmentDeletesKeys = ["siteId", "personId", "isThirdPartyUser", "siteReport", "snowtifyReport"];
const assignmentDeletesHeaders = ["Site ID", "User ID", "Third Party User", "Site Report (PDF)", "Snowtify Report"];

const siteReportSubscription = (siteReport, sendIfNecessary, sendIfHazard) => {
   return `${siteReport
     ? sendIfNecessary
       ? sendIfHazard
         ? 'Hazards Only'
         : 'Events Only'
       : 'Daily'
     : 'No'}`
}

const renderAssignment = ({
  siteId,
  userEmail,
  reportTypes: {site: siteReport, snowtify: snowtifyReport},
  sendIfNecessary,
  sendIfHazard,
  isThirdPartyUser
}) => (
  <>
      <p className={styles.bold}>{`Site ID: ${siteId}, `}</p>
      <p className={styles.bold}>{`User email: ${userEmail || '<NO EMAIL>'}, ${isThirdPartyUser ? 'Third Party' : 'Company' } User, `}</p>
      <p>{`send site report: ${siteReportSubscription(siteReport, sendIfNecessary, sendIfHazard)}, `}</p>
      <p>{`send snowtify report: ${snowtifyReport ? 'Yes' : 'No' }, `}</p>
  </>
);

const renderUpdates = ({
    siteId,
    userEmail,
    reportChanges: {
      old:
        {
          reportTypes: {site: oldSiteReport, snowtify: oldSnowtifyReport},
          sendIfNecessary: oldSendIfNecessary,
          sendIfHazard: oldSendIfHazard
        },
      new: {
        reportTypes: {site: newSiteReport, snowtify: newSnowtifyReport},
        sendIfNecessary: newSendIfNecessary,
        sendIfHazard: newSendIfHazard
      }
    },
    isThirdPartyUser
  }) => (
  <>
    <p className={styles.bold}>{`Site ID: ${siteId}, `}</p>
    <p className={styles.bold}>{`User email: ${userEmail || '<NO EMAIL>'}, ${isThirdPartyUser ? 'Third Party' : 'Company' } User, `}</p>
    <p>{`send site report: ${siteReportSubscription(oldSiteReport, oldSendIfNecessary, oldSendIfHazard)} ↝ ${siteReportSubscription(newSiteReport, newSendIfNecessary, newSendIfHazard)}`}</p>
    <p>{`send snowtify report: ${oldSnowtifyReport ? 'Yes' : 'No' } ↝ ${newSnowtifyReport ? 'Yes' : 'No' }`}</p>
  </>
);

const renderDeletes = ({
    siteId,
    personEmail,
    reportTypes,
    sendIfNecessary,
    sendIfHazard,
    isThirdPartyUser
}) =>(
  <>
    <p className={styles.bold}>{`Site ID: ${siteId}, `}</p>
    <p className={styles.bold}>{`User email: ${personEmail || '<NO EMAIL>'}, ${isThirdPartyUser ? 'Third Party' : 'Company'} User, `}</p>
    { reportTypes
      ?
      <>
        <p>{`send site report: ${siteReportSubscription(reportTypes.siteReport, sendIfNecessary, sendIfHazard)}, `}</p>
        <p>{`send snowtify report: ${reportTypes.snowtify ? 'Yes' : 'No'}, `}</p>
      </>
      : <p>No assigned reports.</p>
    }
  </>
)

const renderDownloadLink = ({fileName, name, assignments, keys, headers}) => (
  <CsvFileDownload
    tooltip={`Download All ${upperFirstChar(name)} Assignments as CSV`}
    data={assignments}
    fileName={`${fileName}.${name}.csv`}
    keys={keys}
    headers={headers}
  />
);

const assignmentId = (s) => `${s.siteId}_${s.userEmail}`;

const fileNameNoSuffix = f =>
  (f || "").substring(f, (f || "").toUpperCase().indexOf(".CSV", 0));

const renderList = (count, arr, name) =>
  (arr || [])
    .filter((el, i) => i <= CUTOFF)
    .map((el) => (
      <div key={assignmentId(el)} id={assignmentId(el)} className={styles["assignmentRow"]}>
        {name === "updates"
          ? renderUpdates(el)
          : name === "deletes"
            ? renderDeletes(el)
            : renderAssignment(el)
        }
      </div>
    ));

const renderRubric = (
  name,
  data,
  foldState,
  setFoldState,
  fileName
) => {
  const assignments = name === "deletes" ? [...data[name].entries.thirdPartyUserAssignments, ...data[name].entries.companyUserAssignments] : data[name].entries
  return(
  <>
    <h4 className={styles.rubricHead}>
      <span
        onClick={
          data[name].count
            ? () =>
                setFoldState(immutable.set(foldState, name, !foldState[name]))
            : null
        }
      >
        <CollapseChevron
          collapsed={foldState[name]}
          id={`${name}-collapse`}
          style={{ marginLeft: "16px" }}
        />
        {`${upperFirstChar(name)}: ${data[name].count} `}
      </span>
      { renderDownloadLink({
        fileName: fileNameNoSuffix(fileName),
        name,
        assignments:
          name === "updates"
            ? assignments.map(entry => (
              {
                ...entry,
                oldSiteReport: siteReportSubscription(entry.reportChanges.old.reportTypes.site, entry.reportChanges.old.sendIfNecessary, entry.reportChanges.old.sendIfHazard),
                oldSnowtifyReport: entry.reportChanges.old.reportTypes.snowtify ? "Yes" : "No",
                newSiteReport: siteReportSubscription(entry.reportChanges.new.reportTypes.site, entry.reportChanges.new.sendIfNecessary, entry.reportChanges.new.sendIfHazard),
                newSnowtifyReport: entry.reportChanges.new.reportTypes.snowtify ? "Yes" : "No",
              }))
            : assignments.map(entry => ({...entry, siteReport: siteReportSubscription(entry.reportTypes?.site, entry.sendIfNecessary, entry.sendIfHazard), snowtifyReport: entry.reportTypes?.snowtify ? "Yes" : "No"})),

        keys: name === "updates"
          ? assignmentUpdatesKeys
          : name === "deletes"
            ? assignmentDeletesKeys
            : assignmentsKeys,
        headers: name === "updates"
          ? assignmentUpdatesHeaders
          : name === "deletes"
            ? assignmentDeletesHeaders
            : assignmentsHeaders,
          })
        }
    </h4>
    {foldState[name] && (
      <>
        <div className={styles["assignmentList"]}>
          {data[name].count
            ? renderList(
                data[name].count,
                assignments,
                name
              )
            : "Nothing to show"}
          {data[name].count > CUTOFF && (
            <div>
              <i className="fa fa-ellipsis-v" />
            </div>
          )}
        </div>
      </>
    )}
  </>
)};

const AssignmentChangeList = ({ data, status, fileName, isFetching}) => {
  const [foldState, setFoldState] = useState({
    untouched: false,
    updates: false,
    deletes: false,
    inserts: false
  });
  return (
    <div>
      {isFetching && (
        <span>
          <BusySpinner />
          Processing Changelist file
        </span>
      )}
      {status === 'SUCCESS' && <div>
        Changelist was processed successfully.
      </div>
      }
      <br />
      For each section, a maximum of 10 entries is shown. The full list can be
      downloaded as CSV files with this icon:{' '}
      <i
        className="fa fa-download"
        style={{ fontSize: "14px", color: "rgb(136, 150, 170)" }}
        aria-hidden="true"
      />
      <br />
      {renderRubric(
        "untouched",
        data,
        foldState,
        setFoldState,
        fileName,
      )}
      {renderRubric(
        "updates",
        data,
        foldState,
        setFoldState,
        fileName
      )}
      {renderRubric(
        "deletes",
        data,
        foldState,
        setFoldState,
        fileName
      )}
      {renderRubric(
        "inserts",
        data,
        foldState,
        setFoldState,
        fileName
      )}
    </div>
  );
};


AssignmentChangeList.propTypes = {
  data: PropTypes.shape({
    untouched: PropTypes.shape({
      count: PropTypes.number,
      entries: PropTypes.array
    }),
    updates: PropTypes.shape({
      count: PropTypes.number,
      entries: PropTypes.array
    }),
    deletes: PropTypes.shape({
      count: PropTypes.number,
      entries: PropTypes.shape(
        {
          companyUserAssignments: PropTypes.array,
          thirdPartyUserAssignments: PropTypes.array
        }
      )
    }),
    inserts: PropTypes.shape({
      count: PropTypes.number,
      entries: PropTypes.array
    })
  }).isRequired
};

export default AssignmentChangeList;
