import { connect } from "react-redux";
import {
  subscribeSiteReport,
  updateReport,
  unsubscribeSiteReport
} from "common/dataRetrieval/ReportsDuck";
import AssignmentPrint from "./AssignmentPrint";
import { matchSearchTerm, isThirdPartyUser } from "common/helpers/helpers";
import { createSelector } from "reselect";

const selectUserReportSubscription = state => state.userReportSubscriptions;
const selectAssignmentList = (state, ownProps) => ownProps.assignmentList;
const selectSearchbox = (state, ownProps) => state.searchbox['assignmentList'];

const filterBySearchbox = (a, searchbox) => {
  if (!searchbox || !searchbox.value) return true;
  const matches = matchSearchTerm(searchbox.value);
  return matches(a.name) || matches(a.user) || matches(a.email);
}

// augment ownProps.assignmentList with current values for report subscriptions
const getCsvAssignmentList = createSelector(
  selectUserReportSubscription,
  selectAssignmentList,
  selectSearchbox,
  (userReportSubscriptions, assignmentList, searchbox) =>
  assignmentList
  .filter(a => filterBySearchbox(a, searchbox))
  .map(a => {
      const report =
        (userReportSubscriptions[a.userId] &&
          userReportSubscriptions[a.userId].find(
            rs => rs.siteId === a.siteId && (rs.type === "site")
          )
        ) || {};
      const snowtify =
        (userReportSubscriptions[a.userId] &&
          userReportSubscriptions[a.userId].find(
            rs => rs.siteId === a.siteId && (rs.type === "snowtify")
          )
        ) || {};
      const isThirdPartyUser = !a.userObj.roles
      return {
        ...a,
        thirdPartyUser: isThirdPartyUser,
        snowtify: Object.keys(snowtify).length !== 0,
        report: report.type
          ? report.sendIfNecessary
            ? report.sendIfHazard
            ? "Hazards Only"
            : "Events Only"
            : "Daily"
          : "",
      };
    })
);

const mapStateToProps = (state, ownProps) => ({
  userReportSubscriptions: state.userReportSubscriptions,
  csvAssignmentList: getCsvAssignmentList(state, ownProps),
  isFetching:
    state.userReportSubscriptions.isFetching ||
    state.userReportSubscriptions.isFetchingGet
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleReportCheckboxChange: (e, user, siteId, siteReportType) => {
    const userType = isThirdPartyUser(user) ? "third-party-user" : "person";
    if (e.target.checked)
      dispatch(
        subscribeSiteReport(user.id, user.companyId, siteId, siteReportType, userType)
      );
    else
      dispatch(
        unsubscribeSiteReport(user.id, user.companyId, siteId, siteReportType, userType)
      );
  },

  handleReportSubtypeChange: (e, user, subscription) => {
    const userType = isThirdPartyUser(user) ? "third-party-user" : "person";
    const sendIfNecessary = e.target.value === "events" || e.target.value === "hazards";
    const sendIfHazard = e.target.value === "hazards";
    dispatch(
      updateReport(user.id, user.companyId, subscription.id, sendIfNecessary, sendIfHazard, userType)
    );
  }
});

const AssignmentPrintContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentPrint);

export default AssignmentPrintContainer;
