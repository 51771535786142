import {connect} from "react-redux";
import {closeUploadModal, uploadFile, applyChangeList, uploadChangeListFile} from "./BulkSiteUserAssignmentFileUploadDuck";
import BulkSiteUserAssignmentFileUploadDialog from "./BulkSiteUserAssignmentFileUploadDialog";
import {onSubmitActions} from "../../../redux-form-submit-saga";


const mapStateToProps = ({bulkSiteUserAssignmentFileUpload, invitePerson, usersManage}) => ({
  showModal: bulkSiteUserAssignmentFileUpload.showModal,
  isFetching: bulkSiteUserAssignmentFileUpload.meta.isFetching,
  uploadError: bulkSiteUserAssignmentFileUpload.bulkData.uploadError,
  uploadSuccess: bulkSiteUserAssignmentFileUpload.bulkData.uploadSuccess,
  recordsAdded: bulkSiteUserAssignmentFileUpload.bulkData.recordsAdded,
  clStatus: bulkSiteUserAssignmentFileUpload.changeListData.status,
  clData: bulkSiteUserAssignmentFileUpload.changeListData.list,
  clError: bulkSiteUserAssignmentFileUpload.changeListData.uploadError,
  clApplyError: bulkSiteUserAssignmentFileUpload.applied.uploadError,
  clApplyStatus: bulkSiteUserAssignmentFileUpload.applied.status,
  clApplyResults: bulkSiteUserAssignmentFileUpload.applied.results,
  fileName: bulkSiteUserAssignmentFileUpload.meta.fileName,
  invitePersonError: invitePerson.errorMessage || usersManage.errorMessage,
  invitePersonFetching: invitePerson.isFetching || usersManage.isFetching,
});

const mapDispatchToProps = dispatch => ({
  closeUploadModal: () => dispatch(closeUploadModal()),
  uploadFile: (file, companyId) => dispatch(uploadFile(file, companyId)),
  uploadChangeListFile: (file, companyId) => dispatch(uploadChangeListFile(file, companyId)),
  applyChangeList: (listData, companyId) => dispatch(applyChangeList(listData, companyId)),
  inviteUser: values => onSubmitActions('INVITEPERSON')(values, dispatch),
  inviteThirdPartyUser: values => onSubmitActions('USEREDIT')(values, dispatch),
})

const BulkSiteUserAssignmentFileUploadContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkSiteUserAssignmentFileUploadDialog)

export default BulkSiteUserAssignmentFileUploadContainer;
