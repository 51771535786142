import React, { useState } from "react";
import { Formik, Form, ErrorMessage, useField } from "formik";
import { Alert, Button, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import BusySpinner from 'common/components/BusySpinner';
import ThreatTypeSelector from "common/components/ThreatTypeSelector";
import 'common/css/inputElements.css';
import "./ReportSettings.css";

const WwaFormikRadio = ({ children, ...props }) => {
  const [field] = useField({ ...props, type: "radio" });
  return (
    <span>
      <input id={props.id} type="radio" {...field} {...props} className="wwa-radio" />
      <label htmlFor={props.id} />
      {children}
    </span>
  );
};

const CustomReportForm = props => {
  const [threatTypesKey, setThreatTypesKey] = useState(0);

  // N.B.: to simplify things, the single source of truth for snow/ice filter values is stored as inches and only displayed in metric units
  const metricUnits = props.reportUnit === 'metric';
  const maxTotalSnow = metricUnits ? 500 * 2.54 : 500;
  const maxTotalIceaccum = metricUnits ? 2 * 2.54 : 2;
  const customReportInitialValues = {...props.customReportInitialValues};
  if(metricUnits){
    customReportInitialValues['totalSnow'] = Number(customReportInitialValues['totalSnowIN'] * 2.54).toFixed(1);
    customReportInitialValues['totalIceaccum'] = Number(customReportInitialValues['totalIceaccum'] * 2.54).toFixed(1);
  } else {
    customReportInitialValues['totalSnow'] = customReportInitialValues['totalSnowIN'];
  }
    
  return (
    <div className="">
      <Formik
        initialValues={customReportInitialValues}
        validate={values => {
          const errors = {};
          const totalSnow = metricUnits ? +values.totalSnow / 2.54 : +values.totalSnow;
          const totalIceaccum = metricUnits ? +values.totalIceaccum/2.54 : +values.totalIceaccum;
          if(values.name.length > 30) {
            errors.name = 'Max 30 characters'
          } 
          if(totalSnow > maxTotalSnow) {
            errors.totalSnow = `Max ${maxTotalSnow}`
          } else if(totalSnow < 0) {
            errors.totalSnow = "No negative"
          } 
          if(totalIceaccum > maxTotalIceaccum) {
            errors.totalIceaccum = `Max ${maxTotalIceaccum}`
          } else if(totalIceaccum < 0) {
            errors.totalIceaccum = "No negative"
          } 
          if(+values.maxPop < 20) {
            errors.maxPop = "Min 20%";
          }
          return errors;
        }}

        onSubmit={(values, { setSubmitting }) => {
          const {name, ...formValues} = values;
          if(metricUnits){
            formValues['totalSnowIN'] = Number(formValues['totalSnow'] / 2.54).toFixed(2);
            formValues['totalIceaccum'] = Number(formValues['totalIceaccum'] / 2.54).toFixed(2);
          } else {
            formValues['totalSnowIN'] = formValues['totalSnow'];
          }
          const allValues = {
            ...formValues,
            threatWeatherPrimaryCodes: { ...props.thrtTypeVal }
          };
          const id = customReportInitialValues.id; // use stable ID, reset to empty mustn't change this
          props.saveCustomReportFilterValues(props.companyId, allValues, name || undefined, id);
        }}
      >
        {({ submitForm, values, errors, touched, handleChange, handleBlur, resetForm, dirty }) => (
          <Form>

            <FormGroup 
              controlId="formValidationError1" 
            >
              <FormLabel>
                <span className="ReportSettings__CustomReportLabel">
                  Name (optional){" "}
                </span>
              </FormLabel>
              <FormControl 
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              <div style={{color:'#a94442', marginTop:'0.5em'}}>
                {(props.apiError || {}).name}
                <ErrorMessage name="name"/>
              </div>
            </FormGroup>

            <FormGroup 
              controlId="formValidationError4" 
            >
              <FormLabel>
                <span className="ReportSettings__CustomReportLabel">
                  POP (probability of precipitation, min 20%) &ge;{" "}
                </span>
              </FormLabel>
              <FormControl 
                type="number"
                name="maxPop"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.maxPop}
              />
              <div style={{color:'#a94442', marginTop:'0.5em'}}>
                <ErrorMessage name="maxPop"/>
              </div>
            </FormGroup>

            <FormGroup 
              controlId="formValidationError2" 
            >
              <FormLabel>
                <span className="ReportSettings__CustomReportLabel">
                  And Total Snow ({metricUnits ? 'cm' : 'inches'}) &ge;{" "}
                </span>
              </FormLabel>
              <FormControl 
                type="number"
                name="totalSnow"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.totalSnow}
              />
              <div style={{color:'#a94442', marginTop:'0.5em'}}>
                <ErrorMessage name="totalSnow"/>
              </div>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <span className="ReportSettings__CustomReportLabel"> And </span>
              </FormLabel>{' '}
              <WwaFormikRadio id="iceSnowPredicateAnd" name="iceSnowPredicate"  value='and'/>

              <FormLabel>
                <span className="ReportSettings__CustomReportLabel"> Or </span>
              </FormLabel>{' '}
              <WwaFormikRadio id="iceSnowPredicateOr" name="iceSnowPredicate"  value='or'/>
            </FormGroup>

            <FormGroup 
              controlId="formValidationError3" 
            >
              <FormLabel>
                <span className="ReportSettings__CustomReportLabel">
                  Total Ice Accumulation ({metricUnits ? 'mm' : 'inches'}) &ge;{" "}
                </span>
              </FormLabel>
              <FormControl 
                type="number"
                name="totalIceaccum"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.totalIceaccum}
              />
              <div style={{color:'#a94442', marginTop:'0.5em'}}>
                <ErrorMessage name="totalIceaccum"/>
              </div>
            </FormGroup>

            <div>
              <span className="ReportSettings__CustomReportLabel">
                And any of
              </span>
              {/* When "Reset" button is pressed, change ThreatTypeSelector's key to reinitialize it.
                Initial values: on first render: show previously saved values; on reset: empty values.
              */}
              <ThreatTypeSelector
                key={threatTypesKey}
                cbGroupId="customReportThreatTypes"
                weatherType={"winter"}
                initialValues={threatTypesKey % 2 === 0
                    ? customReportInitialValues.threatWeatherPrimaryCodes
                    : props.emptyInitialValues.threatWeatherPrimaryCodes
                }
              />
            </div>

            {props.isSavedCustomReportsSuccess && (
              <Alert variant="success">
                Values saved successfully!
               </Alert>
            )}
            {props.apiError && (typeof props.apiError === "string") && 
              <div style={{color:'#a94442', marginTop:'0.5em'}}>
                {props.apiError}
              </div>
            }
            <div className="ReportSettings_Buttons">
              <Button variant="success" onClick={submitForm} disabled={props.isFetching}>
                {props.isFetching ? (
                  <span>
                    <BusySpinner inButton={true} />Saving
                  </span>
                ) : (
                  'Save'
                )}
              </Button>
              {/* Reset to previously saved values */}
              <Button 
                variant="outline-secondary" 
                onClick={() => {
                  resetForm({values: customReportInitialValues});
                  setThreatTypesKey(threatTypesKey * 2);
                }} disabled={props.isFetching}>
                Reset
              </Button>
              {/* Reset to empty values */}
              <Button 
                variant="outline-secondary" 
                onClick={() => {
                  resetForm({values: props.emptyInitialValues});
                  setThreatTypesKey(threatTypesKey *2 + 1)
                }} disabled={props.isFetching}>
                Clear
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
};

export default CustomReportForm;
