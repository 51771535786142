import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Alert,
  OverlayTrigger,
  Popover
} from 'react-bootstrap';
import BusySpinner from "../BusySpinner";
import './BulkSiteUserAssignmentFileUploadDialog.scss'
import AssignmentChangeList from "../AssignmentChangeList";
import NotFoundUsersComponent from "./Errors/NotFoundUsersComponent";

const popoverContent =
  <Popover id="template-fields-description">
    <Popover.Title as="h3">Template fields description</Popover.Title>
    <Popover.Content>
      <p><strong>Site Name</strong> – Optional field.</p>
      <p><strong>City</strong> – Optional field.</p>
      <p><strong>State</strong> – Optional field.</p>
      <p><strong>Zip Code</strong> – Optional field.</p>
      <p><strong>Country</strong> – Optional field.</p>
      <p><strong>Site ID</strong> – <strong>Required</strong> field.</p>
      <p><strong>User Name</strong> – Optional field.</p>
      <p><strong>User Email</strong> – <strong>Required</strong> field.</p>
      <p><strong>Third-Party User</strong> – Optional field. Leave empty for a Company User, or set
        to <strong>TRUE</strong> for a Third-Party User.</p>
      <p><strong>Role</strong> – Optional field. Only applicable to Company Users. Possible values: <strong>Company
        Admin</strong>, <strong>Site Admin</strong>, or <strong>Company User</strong>.</p>
      <p><strong>Site Report (PDF)</strong> – Optional field. Possible values: <strong>Daily</strong>, <strong>Events
        Only</strong>, or <strong>Hazards Only</strong>. Leave empty if you don’t want to create a report subscription.
      </p>
      <p><strong>Snowtify Report</strong> – Optional field. Set to <strong>TRUE</strong> to subscribe, or leave empty.
      </p>
    </Popover.Content>
  </Popover>

class BulkSiteUserAssignmentFileUploadDialog extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      file: undefined,
      showModal: false,
      notFoundUsers: {
        companyUsers: [],
        thirdPartyUsers: []
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const notFoundUsersError = this.props.uploadError || this.props.clError;
    const prevNotFoundUsersError = prevProps.uploadError || prevProps.clError;
    if (
      notFoundUsersError?.code === "NOT_FOUND_USERS" &&
      notFoundUsersError?.details &&
      (
        JSON.stringify(notFoundUsersError.details.companyUsers) !== JSON.stringify(prevNotFoundUsersError?.details?.companyUsers) ||
        JSON.stringify(notFoundUsersError.details.thirdPartyUsers) !== JSON.stringify(prevNotFoundUsersError?.details?.thirdPartyUsers)
      )
    ) {
      this.setState({notFoundUsers: notFoundUsersError.details});
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.showModal !== props.showModal) {
      return {file: null, showModal: props.showModal};
    } else if (props.clStatus === 'SUCCESS') {
      return {file: null};
    }
    return null;
  }

  handleFileNameChange = (e) => {
    const fileList = e.target.files
    return this.setState({file: fileList.length ? fileList[0] : undefined})
  }

  handleFileUpload = (e) => {
    const file = this.state.file
    this.props.uploadFile(file, this.props.companyId)
  }

  handleChangeListFileUpload = (e) => {
    const file = this.state.file
    this.props.uploadChangeListFile(file, this.props.companyId)
  }

  handleChangeListApply = (e) => {
    this.props.applyChangeList(this.props.clData, this.props.companyId)
  }

  inviteUser = user =>{
    const userData = user.isThirdPartyUser
    ? {
        email: user.email,
        name: user.name,
        thirdPartyUser: user.isThirdPartyUser,
        companyId: this.props.companyId,
      }
    : {
        email: user.email,
        username: user.name,
        roles: ['company-user'],
        newRole: user.role.split(' ').map(role => role.toLowerCase()).join('-'),
        companyId: this.props.companyId,
      }


      user.isThirdPartyUser
        ? this.props.inviteThirdPartyUser(userData)
        : this.props.inviteUser(userData);

      if (!this.props.invitePersonError) {
        this.setState(prevState => ({
          ...prevState,
          notFoundUsers: {
            companyUsers: user.isThirdPartyUser
              ? prevState.notFoundUsers.companyUsers
              : prevState.notFoundUsers.companyUsers.filter(u => u.email !== user.email),
            thirdPartyUsers: user.isThirdPartyUser
              ? prevState.notFoundUsers.thirdPartyUsers.filter(u => u.email !== user.email)
              : prevState.notFoundUsers.thirdPartyUsers,
          },
        }));
      }

  }

  showErrors = (e, errorType) => {
    const {companyUsers, thirdPartyUsers} = this.state.notFoundUsers;
    if (e.code === "NOT_FOUND_USERS" && companyUsers.length + thirdPartyUsers.length === 0) {
      return null;
    } else {
      return <Alert variant="warning">
        <h4>Error</h4>
        {e.index !== undefined && <div>At line {e.index+1}</div>}
        <div>{this.parseUploadError(e)}</div>
        {errorType.uploadError &&
          <>
            <br/>
            <p>
              Please make sure the file is formatted according to template file and double check the accuracy of your
              data entry.
            </p>
          </>
        }

      </Alert>
    }

  }

  parseUploadError = ({code, description, details}) => {

    switch (code) {
      case "NOT_FOUND_USERS":
        return <div>
        <p>{description}</p>
          <NotFoundUsersComponent
            data={this.state.notFoundUsers}
            onInviteClick={this.inviteUser}
            isFetching={this.props.invitePersonFetching}
            error={this.props.invitePersonError}
          />
        </div>

      case "WRONG_REPORT_TYPE":
        return <div>
          <p>{description}</p>
          {details.map(({index, assignment}) => (<p key={index+1} style={{margin:0}}>
            Row #{index+1}: Site with ID {assignment.siteId} is {assignment.siteTypes.join(', ')} type(s). {' '}
            {assignment.reportTypes.site && assignment.sendIfHazard && !assignment.siteTypes.includes('nonwinter') && 'Site report trigger Hazard Only can not be used for this type of site.' }
            {assignment.reportTypes.snowtify && !assignment.siteTypes.includes('winter') && 'Snowtify Report can not be used for this type of site.' }
          </p>))}
        </div>

      case "INCORRECT_ASSIGNMENT_DATA":
        return <div>
          <p>{description}</p>
          {details && details.map(({index, missingFields, invalidFields}) => (<p key={index} style={{margin:0}}>
            Row #{index+1} {missingFields.length >0 && `missing fields: ${missingFields.join(', ')}`}.{" "}
            {invalidFields.length >0 && `invalid fields: ${invalidFields.join(', ')}`}.{" "}
          </p>))}
        </div>

      default:
        return <div>
          <p>{description}</p>
          {details && details.map((item, index) => (<p key={index} style={{margin:0}}>{item}</p>))}
        </div>

    }
  }

render()
{
  const {
    title,
    closeUploadModal,
      uploadError,
      uploadSuccess,
      isFetching,
      recordsAdded,
      clData,
      clStatus,
      clError,
      clApplyError,
      clApplyStatus,
      clApplyResults,
      fileName
    } = this.props;

    return(
      <div>
        <Modal
          show={this.props.showModal}
          onHide={closeUploadModal}
          backdrop="static"
          dialogClassName="BulkUploadDialog--wide"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {isFetching
                ? <>
                  File upload in progress{' '}
                  <BusySpinner inButton={true} />
                </>
                : title}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {(uploadSuccess || uploadError) &&
              <div>Site-User assignment processing was
                {!uploadError || (uploadError && uploadError.started) ? ' ' : ' not '}
                started.</div>
            }

            {clApplyStatus !== undefined && !this.state.file &&
              <>
                <h3>Changelist apply</h3>
                {clApplyStatus === 'SUCCESS' && <>
                  <Alert variant="success">
                    {clApplyStatus}
                  </Alert>
                </>}
                {!!clApplyError
                  ? this.showErrors(clApplyError, {changeListApplyError: true})
                  : <>
                    <h4 style={{color: "#7F8FA4"}}>Deleted: {clApplyResults.deleted}</h4>
                    <h4 style={{color: "#7F8FA4"}}>Updated/Inserted: {clApplyResults.added}</h4>
                  </>
                }
              </>
            }

            {!!clError && this.showErrors(clError, {changeListError: true})}


            {!!uploadError && this.showErrors(uploadError, {uploadError: true})}

            {uploadSuccess &&
              <div>
                <Alert variant='success'>
                  <h4>Upload successful</h4>
                  <span>Records added/updated: {recordsAdded}</span>
                </Alert>
              </div>
            }

            {!isFetching
              && (
                <FormGroup controlId={'fileupload'}>
                  <FormLabel>Choose File</FormLabel>
                  <FormControl
                    type='file'
                    onChange={this.handleFileNameChange}
                  />
                </FormGroup>
              )
            }

            <span className=''>
              <a href="/sites-users-assignment-template.csv">Download the CSV Template.</a>
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={popoverContent}
              >
                <i className="fa fa-info-circle" aria-hidden="true" style={{marginLeft: '12px', color: '#337ab7'}}/>
              </OverlayTrigger>
            </span>
            <p>(Accepted Delimiters Semicolons and Commas).</p>
            <p/>
            <span>
              <a href="/sites-users-assignment-template-excel.xlsx">Download the Excel Template. </a>
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={popoverContent}
              >
                <i className="fa fa-info-circle" aria-hidden="true" style={{marginLeft: '12px', color: '#337ab7'}}/>
              </OverlayTrigger>
            </span>

            <p/>
            {fileName && <div>Uploaded file name: {fileName}</div>}

            {
              this.state.file && <div>
                "Upload file" will submit a list of Assignments to be saved for the company, including both
                new assignments and updates to existing ones. "Upload Changelist file" will not save Assignments
                but show the difference between Assignments currently in the system and the uploaded list.
              </div>
            }
            <p/>
            {
              clData && !this.state.file && <>
                <p/>
                <h3>Changelist</h3>
                <AssignmentChangeList
                  data={clData}
                  status={clStatus}
                  fileName={fileName}
                  isFetching={isFetching}
                />
              </>
            }

          </Modal.Body>

          <Modal.Footer>
            {!this.state.file && clStatus === 'SUCCESS' &&
              <Alert variant="info">
                "Apply Changelist" will update all Assignment data including deletes and inserts.
              </Alert>
            }

            <div>
              <Button onClick={closeUploadModal}>Close</Button>
              {' '}
              {this.state.file && (
                <Button
                  variant="success"
                  onClick={this.handleFileUpload}
                  disabled={!this.state.file || isFetching}
                >
                  {isFetching
                    ? <span><BusySpinner inButton={true}/>Uploading file</span>
                    : "Upload file"
                  }

                </Button>
              )}

              {' '}

              {this.state.file &&
                <Button variant="success"
                        onClick={this.handleChangeListFileUpload}
                        disabled={!this.state.file || isFetching}
                >{isFetching
                  ? <span><BusySpinner inButton={true} />Uploading Changelist file</span>
                  : 'Upload Changelist file'}
                </Button>
              }
              {' '}
              {!this.state.file && clStatus === 'SUCCESS' &&
                <Button variant="danger"
                        onClick={this.handleChangeListApply}
                        disabled={isFetching || clStatus !== 'SUCCESS'}
                >{isFetching
                  ? <span><BusySpinner inButton={true} />Applying Changelist</span>
                  : 'Apply Changelist'}
                </Button>
              }
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

BulkSiteUserAssignmentFileUploadDialog.propTypes = {
  closeUploadModal: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  uploadSuccess: PropTypes.bool.isRequired,
  recordsAdded: PropTypes.number,
  uploadError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])
}

export default BulkSiteUserAssignmentFileUploadDialog
