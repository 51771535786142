import React, {useState} from 'react';
import immutable from "object-path-immutable";
import {Alert, Button} from "react-bootstrap";
import {upperFirstChar} from "../../../helpers/helpers";
import CollapseChevron from "../../Collapsible/CollapseChevron";
import CsvFileDownload from "../../CsvFileDownload";
import BusySpinner from "../../BusySpinner";
import './NotFoundUsers.scss';

const companyUserKeys = ['name', 'email', 'password', 'title', 'officePhone', 'extension', 'role', 'mobilePhone'];
const thirdPartyUserKeys = ['name', 'email', 'title', 'companyName', 'officePhone', 'extension', 'mobilePhone'];

const companyUserHeaders = ['User Name (Required)', 'User Email (Required)', 'Password (Optional)', 'Title (Optional)', 'Office Phone (Optional. Format: 111-222-3333)', 'Extn (Optional)', 'Role (Optional)', 'Mobile Phone (Optional. Format: 111-222-3333'];
const thirdPartyUserHeaders = ['User Name (Required)', 'User Email (Required)',	'Title (Optional)',	'Company Name(Optional)',	'Office Phone (Optional. Format: 111-222-3333)',	'Extn (Optional)',	'Mobile Phone (Optional. Format: 111-222-3333'];

const renderDownloadLink = ({fileName, name, users, keys, headers}) => (
  <CsvFileDownload
    tooltip={`Download All ${upperFirstChar(name)} Assignments as CSV`}
    data={users}
    fileName={`${fileName}.${name}.csv`}
    keys={keys}
    headers={headers}
  />
);

const fileNameNoSuffix = f =>
  (f || "").substring(f, (f || "").toUpperCase().indexOf(".CSV", 0));

const renderUsersList = ({ group, data, foldState, setFoldState, fileName, onInviteClick, isFetching, error }) => {

  return data[group].length > 0
    ? <div>
        <h4 className="RubricHead">
          <span
            onClick={
              data[group].length
                ? () =>
                  setFoldState(immutable.set(foldState, group, !foldState[group]))
                : null
            }
          >
            <CollapseChevron
              collapsed={foldState[group]}
              id={`${group}-collapse`}
              style={{marginLeft: "16px"}}
            />
            {`${group === "companyUsers" ? "Company": "Third Party"} Users: ${data[group].length} `}
            </span>
          {renderDownloadLink({
            fileName: fileNameNoSuffix(fileName),
            name: group,
            users: data[group].map(user => ({...user, role: user.role?.split(' ').map(role => role.toLowerCase()).join('-')})),
            keys: group === "companyUsers"
              ? companyUserKeys
              : thirdPartyUserKeys,
            headers: group === "companyUsers"
              ? companyUserHeaders
              : thirdPartyUserHeaders,
          })
          }
        </h4>
        {!error && foldState[group] && (
          <div className="NotFoundUsers--container">
            {
              data[group].map((item, index) => (
                <div key={index} className="NotFoundUsers--item">
                  <p style={{marginBottom: 0}}>{item.email} - {item.name}{item.isThirdPartyUser
                    ? " - Third Party User"
                    : item.role
                      ? ` - ${item.role}`
                      : ""
                  }</p>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => onInviteClick(item)}
                    disabled={isFetching}
                  >{
                    isFetching ? <span><BusySpinner inButton={true}/>Invite</span> : "Invite"
                  }
                  </Button>
                </div>
              ))
            }
          </div>
        )}
        {error && (
          <Alert variant="danger">
            <h4>Error</h4>
            <div>{error}</div>
          </Alert>
        )}
      </div>
    : null;
}


const NotFoundUsersComponent = (props) => {
  const [foldState, setFoldState] = useState({
    companyUsers: false,
    thirdPartyUsers: false,
  });

  return <div>
    {renderUsersList({
      ...props,
      group: 'companyUsers',
      foldState,
      setFoldState,
      fileName: 'Not Found Users',
    })}

    {renderUsersList({
      ...props,
      group: 'thirdPartyUsers',
      foldState,
      setFoldState,
      fileName: 'Not Found Users',
    })}

  </div>
}

export default NotFoundUsersComponent
