import React from "react";
import "common/css/dashboardElements.css";
import chevronUp from "images/dashboard/w_sort_collapse.svg";
import chevronDown from "images/dashboard/w_sort_open.svg";

const CollapseChevron = ({
  collapsed,
  id,
  customClass = "PanelLabel_Chevron",
  style = {}
}) => (
  <span className={customClass} id={id} style={style}>
    {collapsed ? (
      <img src={chevronUp} alt="Open" id={id} />
    ) : (
      <img src={chevronDown} alt="Collapse" id={id} />
    )}
  </span>
);

export default CollapseChevron;
