import immutable from 'object-path-immutable';

export const ASSIGN_FILEUPLOAD_OPEN = 'ASSIGN_FILEUPLOAD_OPEN';
export const ASSIGN_FILEUPLOAD_CLOSE = 'ASSIGN_FILEUPLOAD_CLOSE';
export const ASSIGN_FILEUPLOAD_SUBMIT = 'ASSIGN_FILEUPLOAD_SUBMIT';
export const ASSIGN_FILEUPLOAD_SUCCESS = 'ASSIGN_FILEUPLOAD_SUCCESS';
export const ASSIGN_FILEUPLOAD_FAILURE = 'ASSIGN_FILEUPLOAD_FAILURE';
export const ASSIGN_CHANGELIST_SUBMIT = 'ASSIGN_CHANGELIST_SUBMIT';
export const ASSIGN_CHANGELIST_SUCCESS = 'ASSIGN_CHANGELIST_SUCCESS';
export const ASSIGN_CHANGELIST_FAILURE = 'ASSIGN_CHANGELIST_FAILURE';
export const ASSIGN_CHANGELIST_APPLY_SUBMIT = 'ASSIGN_CHANGELIST_APPLY_SUBMIT';
export const ASSIGN_CHANGELIST_APPLY_SUCCESS = 'ASSIGN_CHANGELIST_APPLY_SUCCESS';
export const ASSIGN_CHANGELIST_APPLY_FAILURE = 'ASSIGN_CHANGELIST_APPLY_FAILURE';


export const closeUploadModal = () => ({
  type: ASSIGN_FILEUPLOAD_CLOSE
})

export const openUploadModal = () => ({
  type: ASSIGN_FILEUPLOAD_OPEN
})

export const uploadFile = (file, companyId) => ({
  type: ASSIGN_FILEUPLOAD_SUBMIT,
  file,
  companyId
})

export const applyChangeList = (listData, companyId, isThirdPartyUser) => ({
  type: ASSIGN_CHANGELIST_APPLY_SUBMIT,
  listData,
  companyId
})

export const uploadChangeListFile = (file, companyId, isThirdPartyUser) => ({
  type: ASSIGN_CHANGELIST_SUBMIT,
  file,
  companyId
})

const handleUploadErrors = data => {
  switch (data.code) {
    case "NETWORK_ERROR":
      return {
        description: "Network error.",
        code: "NETWORK_ERROR"
      }
    case "INTERNAL":
      return {
        description: "Internal server error.",
        code: "INTERNAL"
      };
    case "FILE_PARSE_ERROR":
      return {
        description: "File parse error.",
        code: "FILE_PARSE_ERROR"
      };
    case "NO_FILE":
      return {
        description: "File is empty or doesn't exist.",
        code: "NO_FILE"
      };
    case "EMPTY_FILE":
      return {
        description: "File is empty.",
        code: "EMPTY_FILE"
      };
    case "FILE_NO_DATA":
      return {
        description: "File doesn't contain any site-user assignment data.",
        code: "FILE_NO_DATA"
      };
    case "INCORRECT_ASSIGNMENT_DATA":
      return {
        description: "Some rows have empty fields that must not be empty. Please check your uploaded file.",
        code: "INCORRECT_ASSIGNMENT_DATA",
        details: data.error.details
    };
    case "NOT_FOUND_SITES":
      return {
        description: `${data.error.details.length > 1 ? "Sites with IDs do not exist." : "Site with ID does not exist."}`,
        code: "NOT_FOUND_SITES",
        details: data.error.details
      };
    case "NOT_FOUND_USERS":
      return {
        description: `${(data.error.details.companyUsers.length + data.error.details.thirdPartyUsers.length) > 1 ? "Users with emails do": "User with email does"} not exist. Invite user(s) individually or download .csv file for bulk upload.`,
        code: "NOT_FOUND_USERS",
        details: data.error.details
    };
    case "WRONG_REPORT_TYPE":
      return {
        description: "Wrong report type. Some assignments have an incorrect type of report that does not comply with the site type. Please check your uploaded file.",
        code: "WRONG_REPORT_TYPE",
        details: data.error.details
      }
    default:
     return "";
  }
};

const initialState = {
  showModal: false,
  meta:{
    isFetching: false,
    fileName: undefined
  },
  bulkData: {
    uploadError: undefined,
    uploadSuccess: false,
    recordsAdded: undefined
  },
  // for CSV assignments file to check updates/inserts/deletes/untouched:
  changeListData: {
    status: undefined,
    // map of { inserts: {count:<num>,entries:[]}, updates: {..}, deletes: {..}, untouched: {..}}
    list: undefined
  },
  applied: {
    uploadError: undefined,
    status: undefined,
    results: {}
  }
}

const bulkSiteUserAssignmentFileUpload = (state = initialState, action) => {
  switch (action.type) {
    case ASSIGN_FILEUPLOAD_OPEN:
      return {
        ...state,
        showModal: true,
      }

    case ASSIGN_FILEUPLOAD_CLOSE:
      return initialState

    case ASSIGN_FILEUPLOAD_SUBMIT:
      return immutable(state)
        .set("meta.isFetching", true)
        .set("meta.fileName", action.file.name)
        .set("bulkData", initialState.bulkData)
        .set("applied", initialState.applied)
        .set("changeListData", initialState.changeListData)
        .value();

    case ASSIGN_FILEUPLOAD_SUCCESS:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("bulkData.recordsAdded", action.data.added?.count)
        .set("bulkData.recordsErrorsList", action.data.errors || undefined)
        .set("bulkData.started", action.data.started)
        .set("bulkData.uploadSuccess", !action.data.code)
        .set("bulkData.uploadError", handleUploadErrors(action.data))
        .value();

    case ASSIGN_FILEUPLOAD_FAILURE:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("bulkData.recordsAdded", 0)
        .set("bulkData.uploadSuccess", false)
        .set("bulkData.uploadError", handleUploadErrors(action.data))
        .value();

      case ASSIGN_CHANGELIST_SUBMIT:
        return immutable(state)
          .set("meta.isFetching", true)
          .set("meta.fileName", action.file.name)
          .set("bulkData", initialState.bulkData)
          .set("applied", initialState.applied)
          .set("changeListData", initialState.changeListData)
          .value();

      case ASSIGN_CHANGELIST_SUCCESS:
        return immutable(state)
          .set("meta.isFetching", false)
          .set("changeListData.status", action.data.status)
          .set("changeListData.list", action.data.results)
          .set("changeListData.uploadError", handleUploadErrors(action.data))
          .value();

    case ASSIGN_CHANGELIST_FAILURE:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("changeListData.status", undefined)
        .set("changeListData.list", undefined)
        .set("changeListData.uploadError", handleUploadErrors(action.data))
        .value();

    case ASSIGN_CHANGELIST_APPLY_SUBMIT:
      return immutable(state)
        .set("meta.isFetching", true)
        .set("bulkData", initialState.bulkData)
        .set("applied", initialState.applied)
        .value();

    case ASSIGN_CHANGELIST_APPLY_SUCCESS:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("applied.status", action.data.status)
        .set("applied.results", action.data.results || {})
        .set("applied.uploadError", handleUploadErrors(action.data))
        .value();

    case ASSIGN_CHANGELIST_APPLY_FAILURE:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("applied.status", ((action.data||{}).status) || 'ERROR')
        .set("applied.results", {})
        .set("applied.uploadError", handleUploadErrors(action.data))
        .value();

    default:
      return state

  }
}

export default bulkSiteUserAssignmentFileUpload;
