import {postFormData} from "utils/fetchApi";
import { put, takeEvery, call, select } from 'redux-saga/effects';

import fetchApi from "../../../utils/fetchApi";
import {
  ASSIGN_FILEUPLOAD_SUBMIT,
  ASSIGN_FILEUPLOAD_SUCCESS,
  ASSIGN_FILEUPLOAD_FAILURE,
  ASSIGN_CHANGELIST_SUBMIT,
  ASSIGN_CHANGELIST_APPLY_SUBMIT,
  ASSIGN_CHANGELIST_SUCCESS, ASSIGN_CHANGELIST_FAILURE, ASSIGN_CHANGELIST_APPLY_SUCCESS, ASSIGN_CHANGELIST_APPLY_FAILURE
} from './BulkSiteUserAssignmentFileUploadDuck';
import {COMPANY_PERSONDATA_SUBMIT} from "../../dataRetrieval/CompanyDuck";
import {USER_REPORTSUBS_BY_COMPANY_SUBMIT} from "../../dataRetrieval/ReportsDuck";

export function* fetchFileUploadSaga ({ file, companyId }) {
  try {
    const url = `/Companies/assignSiteToUserAndSubscribe/bulk`;
    const authToken = yield select((state) => state.login.authToken);
    let body = new FormData()
    body.append('file', file)
    body.append('id', companyId)

    const parsedJson = yield call(postFormData, url, { body, authToken })

    if (!parsedJson.error){

      yield put({ type: ASSIGN_FILEUPLOAD_SUCCESS, data: parsedJson.results })

      // Fetch new company data after successful update
      yield put({ type: COMPANY_PERSONDATA_SUBMIT, id: companyId })
      //Fetch new user reports
      yield put({type: USER_REPORTSUBS_BY_COMPANY_SUBMIT, companyId:companyId })

    } else {
      yield put({ type: ASSIGN_FILEUPLOAD_FAILURE,
        data: parsedJson
      })
    }
  } catch (error) {
    yield put({ type: ASSIGN_FILEUPLOAD_FAILURE,
      data: {code: 'NETWORK_ERROR'}
    })
  }
}

export function* postChangeListFile ({companyId, file}) {
  try {

    const url = '/Companies/assignSiteToUserAndSubscribe/bulk/changeList';
    const authToken = yield select((state) => state.login.authToken);

    let body = new FormData()
    body.append('file', file);
    body.append('id', companyId);

    const parsedJson = yield call(postFormData, url, { body, authToken })

    if (!parsedJson.error){
      yield put({ type: ASSIGN_CHANGELIST_SUCCESS, data: parsedJson })
    } else {
      yield put({ type: ASSIGN_CHANGELIST_FAILURE,
        data: parsedJson
      })
    }
  } catch (err) {
    yield put({ type: ASSIGN_CHANGELIST_FAILURE,
      data: {code: 'NETWORK_ERROR'}
    })
  }
}

export function* applyChangeListFile ({companyId, listData}) {
  try {

    const url = `/Companies/assignSiteToUserAndSubscribe/bulk/changeList/apply`;
    const authToken = yield select((state) => state.login.authToken);

    const payload = {
      deletes: listData.deletes,
      inserts: listData.inserts,
      updates: listData.updates,
      id: companyId,
    };

    const parsedJson = yield call(fetchApi, url, { payload, authToken })

    if (!parsedJson.error){
      yield put({ type: ASSIGN_CHANGELIST_APPLY_SUCCESS, data: parsedJson })

      if(parsedJson.results && (parsedJson.results.deleted || parsedJson.results.added)) {
        // Fetch new company data after successful update
        yield put({ type: COMPANY_PERSONDATA_SUBMIT, id: companyId })
        //Fetch new user reports
        yield put({type: USER_REPORTSUBS_BY_COMPANY_SUBMIT, companyId:companyId })
      }
    } else {
      yield put({ type: ASSIGN_CHANGELIST_APPLY_FAILURE,
        data: parsedJson
      })
    }
  } catch (err) {
    yield put({ type: ASSIGN_CHANGELIST_APPLY_FAILURE,
      data: {code: 'NETWORK_ERROR'}
    })
  }
}

export default function* bulkSiteUserAssignmentFileUploadSaga () {
  yield takeEvery(ASSIGN_FILEUPLOAD_SUBMIT, fetchFileUploadSaga);
  yield takeEvery(ASSIGN_CHANGELIST_SUBMIT, postChangeListFile);
  yield takeEvery(ASSIGN_CHANGELIST_APPLY_SUBMIT, applyChangeListFile);
}
