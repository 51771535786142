import log from 'loglevel'
import { put, takeEvery, call, select } from 'redux-saga/effects'

import {
  BULK_FILEUPLOAD_SUBMIT,
  BULK_FILEUPLOAD_SUCCESS,
  BULK_FILEUPLOAD_FAILURE,
  BULK_CHANGELIST_SUBMIT,
  BULK_CHANGELIST_SUCCESS,
  BULK_CHANGELIST_FAILURE,
  BULK_CHANGELIST_APPLY_SUBMIT,
  BULK_CHANGELIST_APPLY_SUCCESS,
  BULK_CHANGELIST_APPLY_FAILURE
} from './BulkUserFileUploadDuck'
import fetchApi, { postFormData } from 'utils/fetchApi'
import { COMPANYDATA_SUBMIT, COMPANY_PERSONDATA_SUBMIT } from 'common/dataRetrieval/CompanyDuck';

export function* fetchFileUploadSaga ({ file, companyId, isThirdPartyUser }) {
  try {
    const url = `/Companies/${companyId}/users/bulk`;
    const authToken = yield select((state) => state.login.authToken);
    let body = new FormData()
    body.append('file', file)
    body.append('isThirdPartyUser', isThirdPartyUser)

    const parsedJson = yield call(postFormData, url, { body, authToken })

    if (!parsedJson.error){

      yield put({ type: BULK_FILEUPLOAD_SUCCESS, data: parsedJson })

      if(!parsedJson.code && (!parsedJson.errors || (parsedJson.errors.length === 0))){
        // Fetch new company data after successful update
        yield put({ type: COMPANY_PERSONDATA_SUBMIT, id: companyId })
        yield put({ type: COMPANYDATA_SUBMIT })
      }

    } else {
      yield put({ type: BULK_FILEUPLOAD_FAILURE,
        uploadError: parsedJson.error.text
      })
    }
  } catch (error) {
    log.error('fetchFileUploadSaga', error)
    yield put({ type: BULK_FILEUPLOAD_FAILURE,
      uploadError: 'Network error.'
    })
  }
}

export function* postChangeListFile ({companyId, file, isThirdPartyUser}) {
  try {
    
    const url = `/Companies/${companyId}/users/bulk/changeList`;
    const authToken = yield select((state) => state.login.authToken);

    let body = new FormData()
    body.append('file', file)
    body.append('isThirdPartyUser', isThirdPartyUser)

    const parsedJson = yield call(postFormData, url, { body, authToken })

    if (!parsedJson.error){
      yield put({ type: BULK_CHANGELIST_SUCCESS, data: parsedJson })
    } else {
      yield put({ type: BULK_CHANGELIST_FAILURE,
        uploadError: parsedJson.error.text
      })
    }
  } catch (err) {
    log.error('postChangeListFile err', err);
    yield put({ type: BULK_CHANGELIST_FAILURE,
      uploadError: 'Network error.'
    })
  }
}

export function* applyChangeListFile ({companyId, listData, isThirdPartyUser}) {
  try {
    
    const url = `/Companies/${companyId}/users/bulk/changeList/apply`;
    const authToken = yield select((state) => state.login.authToken);
    const payload = {
      deletes: listData.deletes,
      inserts: listData.inserts,
      updates: listData.updates,
      isThirdPartyUser
    };

    const parsedJson = yield call(fetchApi, url, { payload, authToken })

    if (!parsedJson.error){
      yield put({ type: BULK_CHANGELIST_APPLY_SUCCESS, data: parsedJson })
      if(parsedJson.results && (parsedJson.results.deleted || parsedJson.results.inserted || parsedJson.results.updated)) {
         // Fetch new company data after successful update
         yield put({ type: COMPANY_PERSONDATA_SUBMIT, id: companyId })
         yield put({ type: COMPANYDATA_SUBMIT })
      }
    } else {
      yield put({ type: BULK_CHANGELIST_APPLY_FAILURE,
        uploadError: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text
      })
    }
  } catch (err) {
    log.error('applyChangeListFile err', err);
    yield put({ type: BULK_CHANGELIST_APPLY_FAILURE,
      uploadError: 'Network error.'
    })
  }
}

// listen for actions of type BULK_FILEUPLOAD_SUBMIT and use them
export default function* bulkFileUploadSaga () {
  yield takeEvery(BULK_FILEUPLOAD_SUBMIT, fetchFileUploadSaga);
  yield takeEvery(BULK_CHANGELIST_SUBMIT, postChangeListFile);
  yield takeEvery(BULK_CHANGELIST_APPLY_SUBMIT, applyChangeListFile);
}

